import React from 'react';
import { motion, useInView } from 'framer-motion';
import { FaRocket, FaChartLine, FaCogs } from 'react-icons/fa';
import CTA from './CTA';

const packsData = [
  {
    title: 'Pack Start',
    description: 'Pour bien débuter',
    icon: <FaRocket size={40} className="text-camel" />,
    features: [
      'Site vitrine (jusqu’à 3 pages).',
      'Design responsive.',
      'Optimisation SEO basique.',
      'Mise en ligne et configuration du domaine.',
      '1 mois de support gratuit.',
    ],
    price: 'À partir de 300€',
    duration: '1 à 2 semaines',
    cta: 'Démarrer avec le Pack Start',
  },
  {
    title: 'Pack Pro',
    description: 'Un site complet et performant',
    icon: <FaChartLine size={40} className="text-camel" />,
    features: [
      'Site complet (jusqu’à 6 pages).',
      'Blog intégré.',
      'SEO avancé (audit, local, optimisation).',
      'Formation personnalisée pour gérer votre site.',
      '3 mois de support gratuit.',
    ],
    price: 'À partir de 850€',
    duration: '2 à 4 semaines',
    cta: 'Choisir le Pack Pro',
  },
  {
    title: 'Pack Sur Mesure',
    description: 'Votre vision, réalisée',
    icon: <FaCogs size={40} className="text-camel" />,
    features: [
      'Analyse approfondie de vos besoins.',
      'Fonctionnalités sur mesure (e-commerce, API, CRM).',
      'SEO personnalisé.',
      'Accompagnement stratégique digital.',
      'Maintenance incluse pendant 12 mois.',
    ],
    price: 'À partir de 2000€',
    duration: 'Variable',
    cta: 'Demandez un devis sur mesure',
  },
];

const ServicesPacksComponent = () => {
  const sectionRef = React.useRef(null);
  const isInView = useInView(sectionRef, { once: true });

  return (
    <div
      ref={sectionRef}
      className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 lg:px-16"
    >
      {packsData.map((pack, index) => (
        <motion.div
          key={index}
          className="bg-white shadow-lg rounded-lg border border-camel/30 p-4 lg:p-8 flex flex-col justify-between hover:shadow-2xl transition-shadow duration-300"
          initial={{ opacity: 0, y: 50 }}
          animate={isInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1.2, delay: index * 0.2 }}
        >
          <div className="text-center mb-4">
            <div className="flex justify-center mb-4">{pack.icon}</div>
            <h3 className="text-cacao text-xl font-black leading-tight">
              {pack.title} <br />
              <span className="block">-</span>
              <span>{pack.description}</span>
            </h3>
          </div>
          <ul className="list-disc pl-5 mb-4">
            {pack.features.map((feature, idx) => (
              <li key={idx}>{feature}</li>
            ))}
          </ul>
          <p className="text-camel text-sm font-semibold mb-4">
            <strong>Prix :</strong> {pack.price} | <strong>Durée :</strong> {pack.duration}
          </p>
          <CTA text={pack.cta} link="/contact" className="text-center" />
        </motion.div>
      ))}
    </div>
  );
};

export default ServicesPacksComponent;
