import React, { useState } from 'react';
import { motion } from 'framer-motion';

const Modal = ({
  isOpen,
  onClose,
  title = "Titre par défaut",
  description = "Description par défaut",
  seoDescription = "SEO Description par défaut",
  technologies = [],
  strengths = [],
  printSupport = [],
  images = [], // Liste des images
  linkText = "Voir plus",
  linkUrl = null,
}) => {
  const [fullscreenImage, setFullscreenImage] = useState(null); // Image affichée en plein écran

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-camel bg-opacity-50 flex items-center justify-center z-50">
      {/* Modal principal */}
      <motion.div
        className="bg-white rounded-lg shadow-lg w-11/12 max-w-3xl p-6 relative"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        transition={{ duration: 0.3 }}
      >
        {/* Bouton de fermeture */}
        <button
          onClick={onClose}
          className="absolute text-cta text-4xl top-1 right-3"
        >
          &times;
        </button>

        {/* Galerie d'images */}
        {images.length > 0 && (
          <div className="mb-6">
            {/* Liste d'aperçus */}
            <div className="flex gap-2 overflow-x-auto">
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Thumbnail ${index + 1}`}
                  onClick={() => setFullscreenImage(image)} // Au clic, afficher en plein écran
                  className="w-16 h-16 object-cover rounded-lg cursor-pointer hover:opacity-75"
                  loading="lazy"
                />
              ))}
            </div>
          </div>
        )}

        {/* Contenu avec défilement */}
        <div className="max-h-[70vh] overflow-y-auto pr-2">
          {/* Titre */}
          <h2 className="text-2xl font-bold text-camel mb-4">{title}</h2>

          {/* Description principale */}
          <p className="mb-4">{description}</p>

          {/* SEO Description */}
          <p className="mb-4">
            <strong>Description:</strong> {seoDescription}
          </p>

          {/* Technologies utilisées */}
          {technologies.length > 0 && (
            <div className="mb-4">
              <h3 className="text-lg font-bold text-camel">Technologies utilisées :</h3>
              <ul className="list-disc list-inside">
                {technologies.map((tech, index) => (
                  <li key={index}>{tech}</li>
                ))}
              </ul>
            </div>
          )}

          {/* Points forts */}
          {strengths.length > 0 && (
            <div className="mb-4">
              <h3 className="text-lg font-bold text-camel">Points forts :</h3>
              <ul className="list-disc list-inside">
                {strengths.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            </div>
          )}

          {/* Supports Print */}
          {printSupport.length > 0 && (
            <div className="mb-4">
              <h3 className="text-lg font-bold text-camel">Supports Print :</h3>
              <ul className="list-disc list-inside">
                {printSupport.map((support, index) => (
                  <li key={index}>{support}</li>
                ))}
              </ul>
            </div>
          )}

          {/* Lien */}
          {linkUrl && (
            <a
              href={linkUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-cta underline"
            >
              {linkText}
            </a>
          )}
        </div>
      </motion.div>

      {/* Vue plein écran */}
      {fullscreenImage && (
        <div
          className="fixed inset-0 bg-camel bg-opacity-90 flex items-center justify-center z-60"
          onClick={() => setFullscreenImage(null)} // Fermer en cliquant sur l'image ou en dehors
        >
          <img
            src={fullscreenImage}
            alt="Fullscreen"
            className="max-w-full max-h-full object-contain cursor-pointer"
            loading="lazy"
          />
        </div>
      )}
    </div>
  );
};

export default Modal;
