import React from 'react';
import Section from '../components/Section';
import ContactForm from '../components/ContactForm';
import { Player } from '@lottiefiles/react-lottie-player';
import { motion } from 'framer-motion';
import animations from '../data/animations';

const Contact = () => {
  return (
    <div className="bg-background text-text lg:pt-12">
      <Section bgColor="bg-white" disablePaddingBottom={true}>
        <div className="flex flex-col lg:flex-row items-center lg:items-start lg:justify-between lg:gap-12">
          <div className="lg:w-1/2 flex flex-col items-center lg:items-start">
            <motion.div
              className="flex justify-center items-center"
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Player
                autoplay
                loop
                src={animations.contactHeaderAnimation}
                className="object-contain w-32 lg:w-64"
              />
            </motion.div>
            <h1 className="text-4xl font-bold text-camel text-center lg:text-left mb-8 lg:mb-4 animate-fadeIn">
              Contactez-moi pour discuter de votre projet web
            </h1>
            <p className="text-lg text-center lg:text-left mb-8 lg:mb-4 animate-slideUp">
              Vous avez un projet en tête, que ce soit la création d’un site
              vitrine, e-commerce ou une stratégie SEO ? Je suis à votre
              disposition pour échanger et vous aider à réaliser votre vision.
              Remplissez le formulaire ci-dessous et je vous recontacterai
              rapidement.
            </p>
          </div>
          <div className="lg:w-1/2">
            <ContactForm endpoint="http://localhost:5001/contact" />
          </div>
        </div>
      </Section>
    </div>
  );
};

export default Contact;
