import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import { Player } from '@lottiefiles/react-lottie-player';
import { H1, H2 } from '../components/Headings';
import { useInView } from 'react-intersection-observer';
import {
  FaHandshake,
  FaCheckCircle,
  FaHeadset,
  FaStar,
  FaUniversalAccess,
} from 'react-icons/fa';
import animations from '../data/animations';
import Section from '../components/Section';

const About = () => {
  // Gestion individuelle de chaque section
  const [section1Ref, inView1] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });
  const [section2Ref, inView2] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });
  const [section3Ref, inView3] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });
  const [section4Ref, inView4] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  return (
    <div>
      {/* Section 1 */}
      <Section bgColor="bg-white" disablePaddingBottom={true}>
        <motion.div
          ref={section1Ref}
          className="flex flex-col lg:flex-row lg:items-center lg:justify-between"
          initial={{ opacity: 0, y: 50 }}
          animate={inView1 ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1.2 }}
        >
          {/* Animation */}
          <motion.div
            className="flex justify-center items-center pb-4 lg:w-1/2"
            initial={{ opacity: 0, x: -100 }}
            animate={inView1 ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.8 }}
          >
            {inView1 && (
              <Player
                autoplay
                loop
                src={animations.aboutHeaderAnimation}
                className="object-contain w-32 lg:w-80"
              />
            )}
          </motion.div>

          {/* Texte */}
          <div className="lg:w-1/2 text-center lg:text-left mb-8 lg:mb-0">
            <H1 className="text-4xl sm:text-5xl font-bold text-camel text-center lg:text-left mb-8 lg:mb-12">
              À propos de moi
            </H1>
            <p className="text-lg">
              Je suis <strong>Sofiane</strong>, développeur web
              expérimenté, spécialisé dans la création de{' '}
              <strong>
                sites internet modernes, sur mesure et performants
              </strong>
              . Avec plus de 7 ans d'expérience, j'aide les entreprises et les
              indépendants à concevoir des solutions web adaptées à leurs
              besoins, tout en garantissant{' '}
              <strong>une navigation fluide</strong>, un design attrayant et un
              excellent <strong>référencement naturel (SEO)</strong>.
            </p>
          </div>
        </motion.div>
      </Section>

      {/* Section 2 */}
      <Section bgColor="bg-grayLight" disablePaddingBottom={true}>
        <motion.div
          ref={section2Ref}
          className="flex flex-col lg:flex-row-reverse lg:items-center lg:justify-between"
          initial={{ opacity: 0, y: 50 }}
          animate={inView2 ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1.2 }}
        >
          {/* Animation */}
          <motion.div
            className="flex justify-center items-center pb-4 lg:w-1/2"
            initial={{ opacity: 0, x: 100 }}
            animate={inView2 ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.8 }}
          >
            {inView2 && (
              <Player
                autoplay
                loop
                src={animations.aboutPassionateDeveloperAnimation}
                className="object-contain w-32 lg:w-80"
              />
            )}
          </motion.div>

          {/* Texte */}
          <div className="lg:w-1/2 text-center lg:text-left mb-8 lg:mb-0">
            <h2 className="text-2xl font-bold mb-2">
              Un passionné web à votre service
            </h2>
            <p className="text-lg">
              Passionné par les nouvelles technologies, je suis constamment en
              veille pour offrir{' '}
              <strong>des solutions web innovantes et performantes</strong>. En
              combinant mon expertise en <strong>développement web</strong> et
              en <strong>stratégies digitales</strong>, j'aide mes clients à{' '}
              <strong>renforcer leur visibilité en ligne</strong>, à attirer
              leur audience cible et à atteindre leurs objectifs.
            </p>

            <h2 className="text-2xl font-bold mb-2 mt-6">
              Expertise en développement web personnalisée
            </h2>
            <p className="text-lg">
              Fort d'une solide expérience acquise à travers divers projets, je
              comprends parfaitement{' '}
              <strong>les attentes spécifiques de chaque client</strong>. Cette
              expertise me permet de proposer{' '}
              <strong>des solutions personnalisées et adaptées</strong> à leur
              domaine d'activité, en répondant précisément à leurs besoins et
              objectifs.
            </p>
          </div>
        </motion.div>
      </Section>

      {/* Section 3 */}
      <Section bgColor="bg-white">
        <motion.div
          ref={section3Ref}
          className="flex flex-col lg:flex-row lg:items-center lg:justify-between"
          initial={{ opacity: 0, y: 50 }}
          animate={inView3 ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1.2 }}
        >
          {/* Animation */}
          <motion.div
            className="flex justify-center items-center pb-4 lg:w-1/2"
            initial={{ opacity: 0, x: -100 }}
            animate={inView3 ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.8 }}
          >
            {inView3 && (
              <Player
                autoplay
                loop
                src={animations.aboutExpertiseAnimation}
                className="object-contain w-32 lg:w-80"
              />
            )}
          </motion.div>

          {/* Texte */}
          <div className="lg:w-1/2 text-center lg:text-left mb-8 lg:mb-0">
            <h2 className="text-2xl font-bold mb-2">
              Un site à votre image, pensé pour votre audience
            </h2>
            <p className="text-lg">
              Mon objectif est de concevoir un{' '}
              <strong>site web sur mesure</strong> qui reflète parfaitement
              votre identité, attire <strong>votre audience cible</strong> et
              améliore votre visibilité en ligne. Je m'engage à offrir une{' '}
              <strong>
                expérience utilisateur fluide, intuitive et engageante
              </strong>
              , pour convertir vos visiteurs en clients fidèles.
            </p>

            <h2 className="text-2xl font-bold mb-2 mt-6">
              Des solutions adaptées à votre budget
            </h2>
            <p className="text-lg">
              Habitué à collaborer avec des <strong>entrepreneurs</strong> et
              des <strong>particuliers</strong>, je conçois des{' '}
              <strong>solutions web flexibles et personnalisées</strong> qui
              s'adaptent parfaitement à vos objectifs. Mon approche combine
              efficacité et créativité, tout en respectant{' '}
              <strong>vos contraintes budgétaires</strong>.
            </p>
          </div>
        </motion.div>
      </Section>

      {/* Section 4 Mes valeurs */}
      <Section bgColor="bg-grayLight" disablePaddingBottom={true}>
        <H2 className="text-2xl font-bold text-center mb-8">Mes valeurs</H2>
        <p className="text-lg text-center max-w-5xl mx-auto mb-8">
          Chaque collaboration repose avant tout sur une{' '}
          <strong>relation de confiance</strong>. Mon engagement est de vous
          accompagner avec <strong>transparence</strong> et{' '}
          <strong>bienveillance</strong>, en plaçant{' '}
          <strong>vos besoins et vos attentes</strong> au cœur du projet. Je
          privilégie une <strong>approche humaine</strong>, basée sur{' '}
          <strong>l'écoute et l'échange</strong>, afin de construire des{' '}
          <strong>solutions adaptées et durables</strong>.{' '}
          <strong>Qualité</strong> et <strong>sérieux</strong> sont des valeurs
          fondamentales qui guident mon travail, car{' '}
          <strong>votre satisfaction est ma priorité</strong>. Mon objectif ?
          Vous offrir un <strong>site web qui reflète votre identité</strong>,
          tout en vous assurant une{' '}
          <strong>expérience fluide et agréable</strong>, aujourd’hui et sur le
          long terme.
        </p>

        <motion.div
          ref={section4Ref}
          className="flex flex-col items-center lg:items-start"
          initial={{ opacity: 0, y: 50 }}
          animate={inView4 ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1.2 }}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6">
            {[
              {
                icon: FaHandshake,
                title: 'Confiance',
                description:
                  'Un accompagnement honnête et transparent, basé sur l’échange et le respect.',
              },
              {
                icon: FaCheckCircle,
                title: 'Engagement',
                description:
                  'Une implication totale pour assurer la réussite de votre projet, avec sérieux et fiabilité.',
              },
              {
                icon: FaHeadset,
                title: 'Écoute',
                description:
                  'Une attention particulière portée à vos attentes, pour créer un site qui vous ressemble vraiment.',
              },
              {
                icon: FaStar,
                title: 'Qualité',
                description:
                  'Un travail soigné, pensé pour durer et répondre à vos besoins avec précision.',
              },
              {
                icon: FaUniversalAccess,
                title: 'Accessibilité',
                description:
                  'Une volonté de rendre le web plus simple et compréhensible pour tous.',
              },
            ].map((value, index) => (
              <div
                key={index}
                className="flex flex-col items-center p-4 bg-white shadow-lg rounded-lg text-center"
              >
                <value.icon className="text-3xl text-camel mb-3" />
                <p className="text-lg font-bold">{value.title}</p>
                <p className="text-sm mt-2">{value.description}</p>
              </div>
            ))}
          </div>
        </motion.div>
      </Section>
    </div>
  );
};

export default About;
