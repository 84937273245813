import React, { useState, useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import { Player } from '@lottiefiles/react-lottie-player';
import Section from '../components/Section';
import { H1, H2 } from '../components/Headings';
import Modal from '../components/Modal';
import images from '../data/images';
import animations from '../data/animations';

const projectsData = [
  {
    title: 'Relax Time Massages',
    description:
      'Site web pour une masseuse indépendante, présentant ses prestations de massage bien-être, un système de réservation en ligne, et des avis clients pour inspirer confiance.',
    image: images.viewCertificationInstitut,
    seoDescription:
      'Relax Time Massages propose des prestations de massage bien-être à domicile et en entreprise autour de Saint-Sulpice-de-Faleyrens (Nouvelle-Aquitaine). Découvrez ses services de massage relaxant, californien, anti-stress et aux huiles chaudes, avec réservation en ligne simple et rapide.',
    technologies: [
      'React JS',
      'TailwindCSS',
      'Node.js',
      'API Google Calendar pour la gestion des rendez-vous',
      'SEO optimisé pour le référencement local',
    ],
    strengths: [
      'Design apaisant et professionnel',
      'Système de réservation en ligne intuitif',
      'Optimisation pour le référencement local',
      'Compatible avec mobile pour une expérience utilisateur fluide',
      "Présence d'une section avis clients pour inspirer confiance",
    ],
    printSupport: [
      'Logo',
      'Cartes de visite',
      'Flyer des prestations',
      'Carte cadeau',
      'Panneau publicitaire',
    ],
    images: [
      images.logoRelaxTimeMassages,
      images.pancarteRelaxTimeMassages,
      images.carteVisiteRelaxTimeMassages,
      images.flyerPrestationsRelaxTimeMassages,
    ],
    linkText: 'Explorer Relax Time Massages',
    linkUrl: 'https://relaxtimemassages.fr',
  },
  {
    title: "L'Élégance Coiffure",
    description:
      'Site vitrine pour un salon de coiffure féminin à Paris, présentant ses services, la prise de rendez-vous en ligne, et une galerie de réalisations pour inspirer confiance et attirer de nouvelles clientes.',
    image: images.elanganceCoiffureHome,
      seoDescription:
      "Découvrez L'Élégance Coiffure, un salon de coiffure parisien dédié aux femmes, offrant des prestations de qualité avec réservation en ligne et une vitrine des créations pour sublimer vos envies capillaires.",
    technologies: [
      'HTML5',
      'CSS3',
      'JavaScript',
      'React JS',
      'TailwindCSS',
      'Node.js',
    ],
    strengths: [
      "Design moderne et élégant reflétant l'image du salon",
      'Système de réservation en ligne intuitif',
      'Galerie de réalisations pour mettre en avant les compétences des coiffeuses',
      'Optimisation SEO pour augmenter la visibilité locale',
      'Site 100% responsive pour une expérience fluide sur mobile et desktop',
    ],
    images: [
      images.elanganceCoiffureHome,
      images.elanganceCoiffurePrestations,
    ],
  },
  {
    title: "Les P'tits Anges",
    description:
      'Site vitrine pour une assistante maternelle, mettant en avant ses services de garde, son expérience professionnelle, et les avis des parents pour instaurer confiance et sérénité.',
    image: images.ptitsAngesHome,
      seoDescription:
      "Découvrez Les P'tits Anges, une assistante maternelle expérimentée, offrant un environnement sécurisé et bienveillant pour les enfants, avec des témoignages de parents et des informations détaillées sur les prestations.",
    technologies: [
      'HTML5',
      'CSS3',
      'JavaScript',
      'React JS',
      'TailwindCSS',
      'Node.js',
    ],
    strengths: [
      'Mise en avant des services de garde personnalisés',
      'Section dédiée aux avis des parents pour inspirer confiance',
      "Design chaleureux et accueillant adapté à l'univers de la petite enfance",
      'Optimisation SEO pour une visibilité locale accrue',
      'Responsive design pour une consultation facile sur mobile et desktop',
    ],
    images: [
      images.ptitsAngesHome,
      images.ptitsAngesAbout,
    ],
  },
  {
    title: 'Cabinet Juridique JD',
    description:
      "Site vitrine pour un cabinet d'avocats, spécialisé en droit des affaires et droit du travail, mettant en avant les prestations juridiques, l'expertise des avocats, et un formulaire de prise de contact pour faciliter les échanges avec les clients.",
    image: images.cabinetJuridiqueHome,
      seoDescription:
      'Cabinet Juridique JD : des avocats experts en droit des affaires et droit du travail, avec un site vitrine professionnel et une présentation détaillée des services pour accompagner vos démarches juridiques.',
    technologies: [
      'HTML5',
      'CSS3',
      'JavaScript',
      'React JS',
      'TailwindCSS',
      'Node.js',
    ],
    strengths: [
      'Design professionnel et épuré reflétant la confiance et le sérieux',
      'Présentation claire des prestations juridiques et des expertises',
      'Formulaire de contact intuitif pour une communication simplifiée',
      'SEO optimisé pour attirer des clients à la recherche de services juridiques',
      'Responsive design pour une accessibilité sur mobile et desktop',
    ],
    printSupport: [
      'Modèles de documents Word pour les contrats et lettres juridiques',
      'Modèles de présentations PowerPoint pour les réunions et conférences',
    ],
    images: [
      images.cabinetJuridiqueHome,
      images.cabinetJuridiqueExamples,
    ],
  },
  {
    title: 'ALissage33',
    description:
      "Création d'une identité visuelle complète pour ALissage33, un service spécialisé dans les lissages capillaires. Ce projet inclut la conception d'un logo professionnel et d'un flyer de prestations pour promouvoir l'activité auprès des clients.",
    image: images.aLissage33Logo,
      seoDescription:
      "ALissage33 : services de lissages capillaires professionnels. Découvrez un logo moderne et un flyer de prestations conçus pour refléter l'élégance et la qualité de leurs services.",
    technologies: [
      'Adobe Illustrator',
      'Adobe Photoshop',
      'Canva', // Si pertinent, sinon supprimer
    ],
    strengths: [
      "Création d'une identité visuelle élégante et moderne",
      'Logo personnalisé pour renforcer la reconnaissance de la marque',
      'Flyer clair et attractif pour promouvoir les prestations',
      "Designs adaptés à l'impression de haute qualité",
    ],
    printSupport: [
      'Logo professionnel',
      'Flyer de prestations avec présentation des services et tarifs',
    ],
    images: [
      images.aLissage33Logo,
      images.aLissage33Flyer,
    ],
    linkText: null, // Pas de site web, donc pas de lien
    linkUrl: null,
  },
];

const Projects = () => {
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { once: true });
  const [selectedProject, setSelectedProject] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedProject(null);
    setIsModalOpen(false);
  };

  return (
    <Section bgColor="bg-white" disablePaddingBottom={true}>
      {/* Titre et Animation */}
      <motion.div
        className="grid grid-cols-1 lg:grid-cols-2 lg:gap-12 items-center"
        initial={{ opacity: 0, y: 50 }}
        animate={isInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1.2 }}
        ref={sectionRef}
      >
        {/* Texte */}
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={isInView ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 1.2 }}
          className="text-center lg:text-left order-2 lg:order-1"
        >
          <H1 className="text-center lg:text-left text-4xl lg:text-5xl font-bold text-camel mb-8">
            Mes projets
          </H1>
          <p className="text-center lg:text-left text-lg  mb-6">
            Découvrez mes récents projets de développement web, allant des sites
            vitrines modernes aux solutions e-commerce sur mesure. Chaque projet
            est conçu pour répondre aux besoins spécifiques de mes clients et
            maximiser leur présence en ligne.
          </p>
        </motion.div>

        {/* Animation Lottie */}
        <motion.div
          initial={{ opacity: 0, x: 50 }}
          animate={isInView ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 1.2 }}
          className="order-1 lg:order-2 flex justify-center lg:justify-end"
        >
          <Player autoplay loop src={animations.projectsHeaderAnimation} className="h-auto w-auto lg:w-auto" />
        </motion.div>
      </motion.div>

      {/* Section des projets */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={isInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1.2 }}
        className="mt-12"
      >
        <H2 className="text-camel">Ils m'ont fait confiance</H2>
        <p className="text-lg mb-12 text-center">Voici quelques exemples de projets réalisés pour mes clients.</p>

        {/* Grid des projets avec hauteur uniforme */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {projectsData.map((project, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 50 }}
              animate={isInView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.8, delay: index * 0.2 }}
              className="flex flex-col h-full"
            >
              <div className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col h-full">
                <img src={project.image} alt={project.title} className="w-full h-48 object-cover" loading="lazy" />
                <div className="p-6 flex flex-col flex-grow">
                  <h3 className="text-xl font-bold text-camel mb-2">{project.title}</h3>
                  <p className="text-md mb-4 flex-grow">{project.description}</p>
                  <button onClick={() => openModal(project)} className="text-cta font-bold text-left hover:underline">
                    En savoir +
                  </button>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>

      {selectedProject && <Modal isOpen={isModalOpen} onClose={closeModal} {...selectedProject} />}
    </Section>
  );
};

export default Projects;
