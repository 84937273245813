import { useEffect, useState } from 'react';

/**
 * Hook personnalisé pour détecter si l'écran est considéré comme mobile
 * @param {number} breakpoint - largeur max (en pixels) pour considérer un écran comme mobile
 */
export default function useIsMobile(breakpoint = 768) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < breakpoint);
    };

    checkMobile(); // Initialisation au chargement
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, [breakpoint]);

  return isMobile;
}
