import React from 'react';
import { motion } from 'framer-motion';
import { Player } from '@lottiefiles/react-lottie-player';
import Section from '../components/Section';
import { H1, H2 } from '../components/Headings';
import CTA from '../components/CTA';
import ServicesPacksComponent from '../components/ServicesPacksComponent';
import ServicesCardComponent from '../components/ServicesCardComponent';
import animations from '../data/animations';
import { FaTools, FaHeadset, FaClock } from 'react-icons/fa';

const Services = () => {
  return (
    <div>
      {/* Section d'introduction */}
      <Section bgColor="bg-white">
        <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-12 items-center">
          {/* Animation Lottie - Première sur mobile (HTML en premier) */}
          <motion.div
            className="flex justify-center items-center order-first lg:order-first lg:col-span-1"
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Player
              autoplay
              loop
              src={animations.servicesHeaderAnimation}
              className="object-contain w-auto"
            />
          </motion.div>

          {/* Texte et CTA - Deuxième sur mobile, texte centré en mobile */}
          <motion.div
            className="order-last lg:order-last lg:col-span-2 text-center lg:text-left"
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <H1 className="lg:text-left">Mes Services</H1>
            <p className="mt-4 text-gray-700">
              Faites passer votre activité à un niveau supérieur grâce à des
              solutions web adaptées. Que ce soit pour la création de sites web,
              l'optimisation SEO ou l'accompagnement stratégique, mes services
              sont conçus pour répondre à vos besoins spécifiques et vous
              permettre d'atteindre vos objectifs en ligne.
            </p>
            <div className="mt-6 text-center lg:text-left">
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <CTA text="Contactez-moi" link="/contact" />
              </motion.div>
            </div>
          </motion.div>
        </div>
      </Section>

      {/* Packs de services */}
      <Section bgColor="bg-grayLight">
        <H2>Packs de services</H2>
        <p className="text-lg text-center mb-6 max-w-3xl mx-auto">
          Choisissez un pack qui correspond à vos objectifs : une solution clé
          en main pour démarrer, un site complet pour performer ou une approche
          sur mesure pour répondre à des besoins spécifiques.
        </p>
        <ServicesPacksComponent />

        <div className="mt-12 text-center">
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <CTA text="Un autre besoin ?" link="/contact" />
          </motion.div>
        </div>
      </Section>

      {/* Services à la carte */}
      <Section
        bgColor="bg-white"
        disablePaddingBottomDesktop
        disablePaddingBottomMobile
      >
        <H2>Services à la carte</H2>
        <p className="text-lg text-center mb-6 max-w-3xl mx-auto">
          Personnalisez votre expérience en choisissant les services adaptés à
          vos besoins. Que ce soit pour un audit, une formation ou une
          optimisation technique, vous avez le contrôle.
        </p>
        <ServicesCardComponent />

        <div className="mt-12 text-center">
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <CTA text="Personnaliser mon service" link="/contact" />
          </motion.div>
        </div>
      </Section>

      {/* À qui correspondent ces services ? */}
      <Section bgColor="bg-white">
        <H2 className="text-center text-camel">
          À qui correspondent ces services ?
        </H2>
        <p className="text-center max-w-2xl mx-auto mt-4">
          Découvrez quel pack ou service correspond le mieux à votre situation
          en fonction de vos besoins spécifiques.
        </p>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-12">
          {/* Packs de services */}
          <motion.div
            className="bg-grayLight p-8 rounded-lg shadow-lg order-first lg:order-first text-center lg:text-left"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.2, delay: 0.2 }}
          >
            <h3 className="text-2xl font-bold text-camel mb-4">
              Packs de services
            </h3>
            <p className="mb-6">
              Mes packs sont adaptés aux professionnels à la recherche de
              solutions clés en main pour leur présence en ligne.
            </p>
            <ul className="space-y-6">
              <li className="flex items-start">
                <span className="text-camel text-2xl font-bold mr-4">1.</span>
                <div>
                  <p className="font-semibold">
                    <strong>Pack Start</strong> : Idéal pour les indépendants et
                    petites entreprises qui débutent.
                  </p>
                  <p className="text-gray-600">
                    <em>Exemple :</em> Une esthéticienne ou un photographe
                    indépendant voulant un site simple pour présenter leurs
                    services.
                  </p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="text-camel text-2xl font-bold mr-4">2.</span>
                <div>
                  <p className="font-semibold">
                    <strong>Pack Pro</strong> : Conçu pour les entreprises
                    souhaitant un site complet et performant.
                  </p>
                  <p className="text-gray-600">
                    <em>Exemple :</em> Une PME avec un blog ou un e-commerce
                    pour attirer et engager ses clients.
                  </p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="text-camel text-2xl font-bold mr-4">3.</span>
                <div>
                  <p className="font-semibold">
                    <strong>Pack Sur Mesure</strong> : Parfait pour les projets
                    nécessitant des fonctionnalités spécifiques.
                  </p>
                  <p className="text-gray-600">
                    <em>Exemple :</em> Une agence immobilière voulant un site
                    multilingue avec un CRM intégré.
                  </p>
                </div>
              </li>
            </ul>
          </motion.div>

          {/* Services à la carte */}
          <motion.div
            className="bg-white p-8 rounded-lg shadow-lg order-last lg:order-last text-center lg:text-left"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.2, delay: 0.4 }}
          >
            <h3 className="text-2xl font-bold text-camel mb-4">
              Services à la carte
            </h3>
            <p className="mb-6">
              Idéal pour des besoins spécifiques ou ponctuels. Ces services
              offrent une flexibilité maximale.
            </p>
            <ul className="space-y-6">
              <li className="flex items-start">
                <span className="text-camel text-2xl font-bold mr-4">1.</span>
                <div>
                  <p className="font-semibold">
                    <strong>Maintenance technique</strong> : Pour assurer le bon
                    fonctionnement de votre site.
                  </p>
                  <p className="text-gray-600">
                    <em>Exemple :</em> Une boutique en ligne ayant besoin de
                    mises à jour régulières et de corrections rapides.
                  </p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="text-camel text-2xl font-bold mr-4">2.</span>
                <div>
                  <p className="font-semibold">
                    <strong>Optimisation SEO avancée</strong> : Pour améliorer
                    votre visibilité sur Google.
                  </p>
                  <p className="text-gray-600">
                    <em>Exemple :</em> Un restaurant souhaitant se positionner
                    sur des mots-clés locaux comme \"meilleur restaurant à
                    Paris\".
                  </p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="text-camel text-2xl font-bold mr-4">3.</span>
                <div>
                  <p className="font-semibold">
                    <strong>Formation personnalisée</strong> : Pour devenir
                    autonome dans la gestion de votre site.
                  </p>
                  <p className="text-gray-600">
                    <em>Exemple :</em> Un auto-entrepreneur apprenant à
                    optimiser son contenu pour le SEO.
                  </p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="text-camel text-2xl font-bold mr-4">4.</span>
                <div>
                  <p className="font-semibold">
                    <strong>Audit de site existant</strong> : Pour identifier
                    les axes d'amélioration de votre site.
                  </p>
                  <p className="text-gray-600">
                    <em>Exemple :</em> Une entreprise souhaitant améliorer les
                    performances techniques et l'expérience utilisateur.
                  </p>
                </div>
              </li>
            </ul>
          </motion.div>
        </div>
        <div className="mt-12 text-center">
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <CTA text="Choisir mon service" link="/contact" />
          </motion.div>
        </div>
      </Section>

      {/* Pourquoi choisir mes services */}
      <Section bgColor="bg-gradient-to-r from-camel via-brun to-camel">
        <H2 className="text-white">Pourquoi choisir mes services ?</H2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
          {/* Card 1: Réactivité et écoute */}
          <motion.div
            className="bg-gray-100 p-6 rounded-lg shadow-lg text-center"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.2, delay: 0.2 }}
          >
            <div className="flex justify-center mb-4">
              <FaHeadset size={40} className="text-camel" />
            </div>
            <h3 className="text-lg font-bold text-camel mb-4">
              Réactivité et écoute
            </h3>
            <p>
              Je travaille en étroite collaboration avec vous pour garantir que
              vos attentes soient non seulement entendues, mais surpassées.
            </p>
          </motion.div>

          {/* Card 2: Solutions personnalisées */}
          <motion.div
            className="bg-gray-100 p-6 rounded-lg shadow-lg text-center"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.2, delay: 0.4 }}
          >
            <div className="flex justify-center mb-4">
              <FaTools size={40} className="text-camel" />
            </div>
            <h3 className="text-lg font-bold text-camel mb-4">
              Solutions personnalisées
            </h3>
            <p>
              Chaque projet est unique. Je m'engage à fournir des solutions qui
              s'adaptent spécifiquement à vos besoins et à vos objectifs.
            </p>
          </motion.div>

          {/* Card 3: Respect des délais */}
          <motion.div
            className="bg-gray-100 p-6 rounded-lg shadow-lg text-center"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.2, delay: 0.6 }}
          >
            <div className="flex justify-center mb-4">
              <FaClock size={40} className="text-camel" />
            </div>
            <h3 className="text-lg font-bold text-camel mb-4">
              Respect des délais
            </h3>
            <p>
              Avec une méthodologie claire et efficace, je m'assure que votre
              projet soit livré dans les temps tout en respectant les standards
              de qualité.
            </p>
          </motion.div>
        </div>
        <div className="mt-12 text-center">
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <CTA text="Démarrer mon projet" link="/contact" />
          </motion.div>
        </div>
      </Section>
    </div>
  );
};

export default Services;
