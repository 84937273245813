import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = () => {
  const navbarRef = useRef(null); // Référence pour la navbar
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true); // Gère la visibilité de la navbar
  const [lastScrollY, setLastScrollY] = useState(0); // Position précédente du scroll
  const location = useLocation();

  useEffect(() => {
    const navbar = navbarRef.current;

    // Fonction pour calculer le padding-top
    const updateBodyPadding = () => {
      const navbarHeight = navbar.offsetHeight;
      document.body.style.paddingTop = `${navbarHeight}px`;
    };

    // Fonction pour gérer le défilement
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY && currentScrollY > 50) {
        // Si on scroll vers le bas, on cache la navbar
        setIsVisible(false);
      } else {
        // Si on scroll vers le haut, on affiche la navbar
        setIsVisible(true);
      }

      setLastScrollY(currentScrollY);
    };

    updateBodyPadding(); // Applique la hauteur au démarrage
    window.addEventListener("resize", updateBodyPadding); // Mets à jour lors du redimensionnement
    window.addEventListener("scroll", handleScroll); // Ajoute l'écouteur pour le scroll

    return () => {
      window.removeEventListener("resize", updateBodyPadding);
      window.removeEventListener("scroll", handleScroll);
      document.body.style.paddingTop = ""; // Réinitialise le padding
    };
  }, [lastScrollY]); // Dépend de la position précédente du scroll

  // Fonction pour ouvrir/fermer le menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Fonction pour fermer le menu
  const closeMenu = () => {
    setIsOpen(false);
  };

  // Vérifie si le lien est actif
  const isActive = (path) => location.pathname === path;

  return (
    <header
      ref={navbarRef}
      className={`fixed top-0 left-0 w-full bg-white z-50 shadow-md transition-transform duration-300 ${
        isVisible ? "translate-y-0" : "-translate-y-full"
      }`}
    >
      <nav className="container mx-auto flex justify-between items-center px-6 py-3">
        {/* Logo */}
        <Link to="/" title="Retour à l'accueil">
          <img src={logo} alt="Logo Sofiane Développeur Web" className="h-16 rounded-full" loading="lazy" />
        </Link>

        {/* Bouton hamburger pour mobile */}
        <div className="lg:hidden">
          <button onClick={toggleMenu} className="focus:outline-none">
            {isOpen ? <FaTimes size={30} className="text-camel" /> : <FaBars size={30} className="text-camel" />}
          </button>
        </div>

        {/* Liens du menu desktop */}
        <div className="hidden lg:flex space-x-6">
          <Link
            to="/"
            className={`${
              isActive("/") ? "border-b-2 border-camel" : "hover:text-camel"
            } text-cacao font-bold transition duration-300`}
          >
            Accueil
          </Link>
          <Link
            to="/about"
            className={`${
              isActive("/about") ? "border-b-2 border-camel" : "hover:text-camel"
            } text-cacao font-bold transition duration-300`}
          >
            À propos
          </Link>
          <Link
            to="/services"
            className={`${
              isActive("/services") ? "border-b-2 border-camel" : "hover:text-camel"
            } text-cacao font-bold transition duration-300`}
          >
            Services
          </Link>
          <Link
            to="/projects"
            className={`${
              isActive("/projects") ? "border-b-2 border-camel" : "hover:text-camel"
            } text-cacao font-bold transition duration-300`}
          >
            Projets
          </Link>
          <Link
            to="/contact"
            className={`${
              isActive("/contact") ? "border-b-2 border-camel" : "hover:text-camel"
            } text-cacao font-bold transition duration-300`}
          >
            Contact
          </Link>
        </div>

        {/* Menu mobile */}
        <div
          className={`${
            isOpen ? "block" : "hidden"
          } lg:hidden absolute top-16 left-0 w-full bg-camel mt-4 py-6 z-50`}
        >
          <Link
            to="/"
            className="block text-white font-bold text-lg text-center my-2 py-2 hover:text-brun"
            onClick={closeMenu}
          >
            Accueil
          </Link>
          <Link
            to="/about"
            className="block text-white font-bold text-lg text-center my-2 py-2 hover:text-brun"
            onClick={closeMenu}
          >
            À propos
          </Link>
          <Link
            to="/services"
            className="block text-white font-bold text-lg text-center my-2 py-2 hover:text-brun"
            onClick={closeMenu}
          >
            Services
          </Link>
          <Link
            to="/projects"
            className="block text-white font-bold text-lg text-center my-2 py-2 hover:text-brun"
            onClick={closeMenu}
          >
            Projets
          </Link>
          <Link
            to="/contact"
            className="block text-white font-bold text-lg text-center my-2 py-2 hover:text-brun"
            onClick={closeMenu}
          >
            Contact
          </Link>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
