import React from 'react';
import Section from '../components/Section';
import illustrationLegal from '../assets/images/illustrations/illustration-mention-legals.svg';

const PrivacyPolicy = () => {
  return (
    <div className="bg-background text-text lg:pt-12">
      <Section bgColor="bg-white">
        {/* Illustration */}
        <div className="flex justify-center mb-8">
          <img
            src={illustrationLegal}
            alt="Illustration légale"
            className="max-w-full h-auto w-32 lg:w-64"
            loading="lazy"
          />
        </div>

        {/* Titre principal */}
        <h1 className="text-4xl lg:text-5xl text-center font-bold text-camel mb-8 animate-fadeIn">
          Politique de confidentialité
        </h1>

        {/* Contenu */}
        <div className="text-lg">
          {/* Introduction */}
          <p>
            Chez <strong>Sofiane.ovh</strong>, je respecte la vie
            privée de mes clients, visiteurs, et utilisateurs de mon site web. Je
            m'engage à protéger vos données personnelles conformément au
            <strong> Règlement Général sur la Protection des Données (RGPD)</strong> et aux lois
            en vigueur en France.
          </p>

          {/* Données personnelles collectées */}
          <h2 className="text-3xl font-bold text-camel mt-8 mb-4">
            1. Données personnelles collectées
          </h2>
          <p>
            Je collecte uniquement les données nécessaires à la gestion de vos
            demandes et à l'exécution de mes services. Les données personnelles
            que je peux collecter incluent :
          </p>
          <ul className="list-disc ml-8">
            <li>Nom et prénom</li>
            <li>Adresse e-mail</li>
            <li>Numéro de téléphone (le cas échéant)</li>
            <li>Message que vous envoyez via le formulaire de contact</li>
            <li>
              Données techniques (adresse IP, navigateur utilisé, pages visitées)
              à des fins statistiques.
            </li>
          </ul>

          {/* Utilisation des données */}
          <h2 className="text-3xl font-bold text-camel mt-8 mb-4">
            2. Utilisation des données
          </h2>
          <p>
            Vos données personnelles sont utilisées dans les cas suivants :
          </p>
          <ul className="list-disc ml-8">
            <li>Répondre à vos demandes via le formulaire de contact.</li>
            <li>Communiquer avec vous concernant des prestations ou projets.</li>
            <li>
              Vous envoyer des informations liées à mes services, avec votre
              consentement préalable.
            </li>
            <li>
              Améliorer l'expérience utilisateur sur mon site.
            </li>
          </ul>

          {/* Conservation des données */}
          <h2 className="text-3xl font-bold text-camel mt-8 mb-4">
            3. Conservation des données
          </h2>
          <p>
            Vos données personnelles sont conservées uniquement pour la durée
            nécessaire :
          </p>
          <ul className="list-disc ml-8">
            <li>
              Données collectées via le formulaire : conservées pendant <strong>6 mois</strong>.
            </li>
            <li>
              Données liées à des factures ou obligations légales : conservées
              jusqu'à <strong>10 ans</strong>, conformément à la réglementation.
            </li>
          </ul>

          {/* Vos droits */}
          <h2 className="text-3xl font-bold text-camel mt-8 mb-4">
            4. Vos droits
          </h2>
          <p>
            Vous disposez des droits suivants sur vos données personnelles :
          </p>
          <ul className="list-disc ml-8">
            <li>Droit d'accès</li>
            <li>Droit de rectification</li>
            <li>Droit à l’effacement</li>
            <li>Droit d’opposition</li>
            <li>Droit à la portabilité</li>
          </ul>
          <p>
            Pour exercer ces droits, contactez-moi à l’adresse suivante :{" "}
            <strong>contact@sofiane.ovh</strong>.
          </p>

          {/* Cookies */}
          <h2 className="text-3xl font-bold text-camel mt-8 mb-4">
            5. Cookies
          </h2>
          <p>
            Mon site utilise des cookies pour améliorer votre expérience et
            collecter des données anonymes à des fins d’analyse. Vous pouvez
            gérer vos préférences dans les paramètres de votre navigateur.
          </p>

          {/* Sécurité des données */}
          <h2 className="text-3xl font-bold text-camel mt-8 mb-4">
            6. Sécurité des données
          </h2>
          <p>
            Je mets en œuvre des mesures techniques et organisationnelles pour
            protéger vos données personnelles contre les accès non autorisés, les
            pertes ou destructions accidentelles.
          </p>

          {/* Contact */}
          <h2 className="text-3xl font-bold text-camel mt-8 mb-4">
            7. Contact
          </h2>
          <p>
            Pour toute question concernant cette politique de confidentialité,
            contactez-moi :
          </p>
          <ul>
            <li>Email : contact@sofiane.ovh</li>
          </ul>

          {/* Modifications */}
          <h2 className="text-3xl font-bold text-camel mt-8 mb-4">
            8. Modifications
          </h2>
          <p>
            Cette politique peut être mise à jour. Toute modification sera
            publiée sur cette page avec la date de mise à jour.
          </p>
        </div>
      </Section>
    </div>
  );
};

export default PrivacyPolicy;
