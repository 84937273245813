///// Import des animations depuis le dossier assets/animations
// Services
import servicesHeaderAnimation from '../assets/animations/services/services-header-animation.json';
// About
import aboutHeaderAnimation from '../assets/animations/about/about-header-animation.json';
import aboutExpertiseAnimation from '../assets/animations/about/about-expertise-animation.json';
import aboutAnalyseAnimation from '../assets/animations/about/about-analyse-animation.json';
import aboutPassionateDeveloperAnimation from '../assets/animations/about/about-passionate-developer-animation.json';
// Projects
import projectsHeaderAnimation from '../assets/animations/projects/projects-header-animation.json';
// Contact
import contactHeaderAnimation from '../assets/animations/contact/contact-header-animation.json';
// Centralisation des animations
const animations = {
    //Services
    servicesHeaderAnimation,
    //About
    aboutHeaderAnimation,
    aboutExpertiseAnimation,
    aboutAnalyseAnimation,
    aboutPassionateDeveloperAnimation,
    // Projects
    projectsHeaderAnimation,
    // Contact
    contactHeaderAnimation,
};

export default animations;
