// src/data/images.js
// banners
import homeBanner from '../assets/images/banners/home-banner.webp'; 
import homeBannerMobile from '../assets/images/banners/home-banner-mobile.webp'; 

// illustrations
import illustrationProjects from '../assets/images/illustrations/illustration-responsive.svg'; 
import illustrationQuestion from '../assets/images/illustrations/illustration-question.svg';
// projects
import maquetteRelaxTimeMassages from '../assets/images/projects/maquette-relaxtimemassages.png'; 
import elanganceCoiffureHome from '../assets/images/projects/elegance-coiffure-home.png'; 
import elanganceCoiffurePrestations from '../assets/images/projects/elegance-coiffure-prestations.png'; 
import ptitsAngesHome from '../assets/images/projects/ptits-anges-home.png'; 
import ptitsAngesAbout from '../assets/images/projects/ptits-anges-about.png'; 
import cabinetJuridiqueHome from '../assets/images/projects/cabinet-juridique-jd-home.png'; 
import cabinetJuridiqueExamples from '../assets/images/projects/cabinet-juridique-jd-examples.png'; 
import aLissage33Logo from '../assets/images/projects/logo-alissage33.png'; 
import aLissage33Flyer from '../assets/images/projects/flyer-alissage33.png'; 
import logoRelaxTimeMassages from '../assets/images/projects/logo-relaxtimemassages.png';
import pancarteRelaxTimeMassages from '../assets/images/projects/pancarte-relaxtimemassages.png';
import viewCertificationInstitut from '../assets/images/projects/view-certification-institut.webp';
import carteVisiteRelaxTimeMassages from '../assets/images/projects/carte-visite-relaxtimemassages.png';
import flyerPrestationsRelaxTimeMassages from '../assets/images/projects/flyer-prestations-relaxtimemassages.png'; 

const images = {
    // banners
    homeBanner,
    homeBannerMobile,
    // illustrations
    illustrationProjects,
    illustrationQuestion,
    // projects
    maquetteRelaxTimeMassages,
    elanganceCoiffureHome,
    elanganceCoiffurePrestations,
    ptitsAngesHome,
    ptitsAngesAbout,
    cabinetJuridiqueHome,
    cabinetJuridiqueExamples,
    aLissage33Logo,
    aLissage33Flyer,
    logoRelaxTimeMassages,
    pancarteRelaxTimeMassages,
    viewCertificationInstitut,
    carteVisiteRelaxTimeMassages,
    flyerPrestationsRelaxTimeMassages
};

export default images;
