import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import Section from './Section';
import { H2 } from './Headings';
import CTA from './CTA';

const FaqSection = () => {
  const faqs = [
    {
      question: 'Combien de temps faut-il pour créer un site ?',
      answer:
        'La durée dépend de la complexité de votre projet. En moyenne, un site vitrine simple peut être réalisé en 2 à 4 semaines. Pour des projets plus complexes, un planning détaillé sera établi.',
    },
    {
      question: 'Quel budget dois-je prévoir ?',
      answer:
        "Le coût varie selon vos besoins : un site vitrine simple est plus abordable qu'une boutique en ligne ou une application web sur mesure. Je propose des solutions adaptées à votre budget avec un devis détaillé.",
    },
    {
      question: 'Comment pouvez-vous m’aider à attirer plus de clients ?',
      answer:
        'Je combine un design moderne, une optimisation SEO avancée et des stratégies marketing digital pour rendre votre site visible sur Google et attrayant pour vos visiteurs.',
    },
  ];

  // Référence pour les animations au scroll
  const faqRef = useRef(null);
  const isInViewFaq = useInView(faqRef, { once: true });

  return (
    <Section bgColor="bg-gradient-to-r from-camel via-brun to-camel">
      <motion.div
        ref={faqRef}
        initial={{ opacity: 0, y: 50 }}
        animate={isInViewFaq ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1.2 }}
        className="w-full"
      >
        {/* Titre principal */}
        <H2 className="text-center text-white mb-2">
          Vous avez des questions ? <br /> Voici quelques réponses
        </H2>
        <p className="text-xl text-center text-white mb-12 max-w-3xl mx-auto">
          Nous savons que <strong>chaque projet est unique</strong> et soulève
          des interrogations. Voici quelques{' '}
          <strong>réponses aux questions les plus fréquentes</strong> pour vous
          guider.
        </p>

        {/* Liste des FAQ */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-4 lg:px-24">
          {faqs.map((faq, index) => (
            <motion.div
              key={index}
              className="bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300"
              initial={{ opacity: 0, y: 50 }}
              animate={isInViewFaq ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.8, delay: index * 0.2 }}
            >
              <h3 className="text-xl font-bold text-cacao mb-2">
                {faq.question}
              </h3>
              <p>{faq.answer}</p>
            </motion.div>
          ))}
        </div>

        {/* Appel à l'action */}
        <div className="text-center mt-12">
          <CTA
            className="inline bg-cta text-white hover:bg-white hover:text-camel transition-colors duration-300"
            text="Une autre question ?"
            link="/contact"
          />
        </div>
      </motion.div>
    </Section>
  );
};

export default FaqSection;
