import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FaChevronLeft, FaChevronRight, FaStar } from 'react-icons/fa';
import CTA from '../components/CTA';

const testimonials = [
  {
    name: 'Sabrina R.',
    feedback:
      'Sofiane a transformé ma vision en réalité. Son expertise et son attention aux détails sont incroyables. Mon site web est parfait !',
    rating: 5,
  },
  {
    name: 'Julien M.',
    feedback:
      "Grâce à Sofiane, j'ai augmenté mes clients de 30% en 3 mois. Son travail sur le SEO est exceptionnel.",
    rating: 4,
  },
  {
    name: 'Liana K.',
    feedback:
      'Un grand merci à Sofiane pour sa disponibilité et son professionnalisme. Mon site est tout simplement génial !',
    rating: 5,
  },
  {
    name: 'Marc T.',
    feedback:
      'Sofiane a livré un site rapide et bien référencé. Je suis impressionné par son efficacité !',
    rating: 5,
  },
  {
    name: 'Claire V.',
    feedback:
      'Le design de mon site est tout simplement superbe. Merci pour ton travail incroyable !',
    rating: 5,
  },
  {
    name: 'Samy E.',
    feedback:
      "Excellent travail. Le site reflète mon activité. Un très grand merci à Sofiane pour l'accompagnement de grande qualité. Je recommande !",
    rating: 5,
  },
];

const TestimonialSlider = () => {
  return (
    <div className="bg-white relative">
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={16}
        slidesPerView={1}
        autoplay={{ delay: 5000 }}
        loop={true}
        navigation={{
          nextEl: '.custom-next',
          prevEl: '.custom-prev',
        }}
        pagination={{
          clickable: true,
          el: '.custom-pagination',
        }}
        breakpoints={{
          640: { slidesPerView: 1, pagination: true },
          1024: { slidesPerView: 2, pagination: false },
          1440: { slidesPerView: 3, pagination: false },
        }}
        className="max-w-5xl mx-auto py-4"
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <div className="bg-white my-2 p-4 md:p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 flex flex-col items-center text-center min-h-[200px]">
              {/* Étoiles */}
              <div className="flex justify-center mb-2 text-yellow-500">
                {[...Array(testimonial.rating)].map((_, i) => (
                  <FaStar key={i} className="w-4 h-4" />
                ))}
              </div>
              {/* Feedback */}
              <p className="text-gray-700 italic text-sm lg:text-base mb-2">
                "{testimonial.feedback}"
              </p>
              {/* Nom */}
              <p className="text-cacao font-semibold text-sm lg:text-base">
                {testimonial.name}
              </p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Pagination pour mobile */}
      <div className="custom-pagination md:hidden flex justify-center mt-4"></div>

      {/* Chevrons uniquement sur desktop */}
      <button
        className="custom-prev hidden md:block absolute -left-10 top-1/2 transform -translate-y-1/2 text-camel hover:text-cta transition-colors z-10"
        aria-label="Précédent"
      >
        <FaChevronLeft size={24} />
      </button>
      <button
        className="custom-next hidden md:block absolute -right-10 top-1/2 transform -translate-y-1/2 text-camel hover:text-cta transition-colors z-10"
        aria-label="Suivant"
      >
        <FaChevronRight size={24} />
      </button>
    </div>
  );
};

export default TestimonialSlider;
