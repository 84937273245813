import React, { useState, useRef } from 'react';
import {
  FaChevronDown,
  FaChevronUp,
  FaCode,
  FaSearch,
  FaBullhorn,
  FaCog,
  FaLaptopCode,
  FaChartLine,
  FaBusinessTime,
  FaHandHoldingHeart,
} from 'react-icons/fa';
import images from '../data/images';
import { motion, useInView } from 'framer-motion';
import Section from '../components/Section';
import { H1, H2, H3 } from '../components/Headings';
import CTA from '../components/CTA';
import TypewriterText from '../components/TypewriterText';
import ServicesPacksComponent from '../components/ServicesPacksComponent';
import ServicesCardComponent from '../components/ServicesCardComponent';
import FaqSection from '../components/FaqSection';
import TestimonialSlider from '../components/TestimonialSlider';
import useIsMobile from '../hooks/useIsMobile';

const servicesData = [
  {
    title: 'Développement web',
    description:
      "Création de sites vitrines, e-commerce, et d'applications web sur mesure.",
    link: '/services#developpement-web',
    icon: <FaCode size={40} className="text-brun" />,
  },
  {
    title: 'SEO & Marketing',
    description:
      'Optimisation pour les moteurs de recherche et stratégies marketing digital.',
    link: '/services#seo-marketing',
    icon: <FaSearch size={40} className="text-brun" />,
  },
  {
    title: 'Identité visuelle & Support print',
    description:
      'Création de votre identité visuelle avec des logos, charte graphique et supports imprimés.',
    link: '/services#identite-visuelle',
    icon: <FaBullhorn size={40} className="text-brun" />,
  },
  {
    title: 'Maintenance informatique',
    description:
      'Support technique et maintenance pour assurer la continuité de vos activités.',
    link: '/services#maintenance-informatique',
    icon: <FaCog size={40} className="text-brun" />,
  },
];

const AccordionItem = ({ stepNumber, title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="bg-white shadow-lg rounded-lg mb-4">
      <div
        className="flex justify-between items-center p-4 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center">
          <span className="text-camel text-lg font-bold mr-4">
            {stepNumber}
          </span>
          <h3 className="text-xl font-bold text-cacao text-left">{title}</h3>
        </div>
        {isOpen ? (
          <FaChevronUp className="text-camel" />
        ) : (
          <FaChevronDown className="text-camel" />
        )}
      </div>
      {isOpen && (
        <div className="p-4 border-t border-gray-200">
          <p className="text-left">{content}</p>
        </div>
      )}
    </div>
  );
};

const Home = () => {

  const isMobile = useIsMobile();

  const words = [
    'Rencontre |',
    'Étude du projet |',
    'Réalisation |',
    'Livraison |',
    'Accompagnement |',
  ];

  // Ref et état de visibilité pour les sections
  const servicesRef = useRef(null);
  const isInViewServices = useInView(servicesRef, { once: true });

  const processRef = useRef(null);
  const isInViewProcess = useInView(processRef, { once: true });

  const testimonialsRef = useRef(null);
  const isInViewTestimonials = useInView(testimonialsRef, { once: true });

  return (
    <div>
      <Section className="relative h-screen">
        {/* Conteneur pour gérer le scaling */}
        <div className="absolute inset-0 overflow-hidden">
          {/* Image de fond avec scale */}
          <motion.img
            src={isMobile ? images.homeBannerMobile : images.homeBanner}
            alt="Hero Background"
            className="absolute inset-0 w-full h-full object-cover"
            initial={{ scale: 1.1 }}
            animate={{ scale: 1 }}
            transition={{
              duration: 8,
              ease: 'easeInOut',
              repeat: Infinity,
              repeatType: 'reverse',
            }}
            style={{ willChange: 'transform' }}
          />
        </div>
        <div className="absolute inset-0 bg-cacao bg-opacity-60 z-10"></div>
        <div className="relative flex flex-col justify-center items-center text-center text-white h-full px-4 md:px-8 lg:px-16 z-20">
          <motion.h1
            className="text-white text-4xl md:text-5xl font-bold leading-snug"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, ease: 'easeOut' }}
          >
            Boostez votre activité avec un site qui <br />
            attire et convertit vos clients
          </motion.h1>
          <motion.p
            className="text-lg mt-4 mb-6"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, ease: 'easeOut', delay: 0.5 }}
          >
            Je suis <strong>Sofiane</strong>, développeur web avec plus de 7 ans
            d'expérience. Je vous aide à atteindre vos{' '}
            <strong>objectifs</strong> grâce à des{' '}
            <strong>solutions web modernes</strong>, optimisées pour{' '}
            <strong>Google</strong> et <strong>adaptées à vos besoins</strong>.
          </motion.p>
          <motion.div
            className="text-center pt-8"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8, ease: 'easeOut', delay: 1 }}
          >
            <CTA
              className="inline bg-cta text-white hover:bg-white hover:text-camel transition-colors duration-300"
              text="Discutons de votre projet"
              link="/contact"
            />
          </motion.div>
        </div>
      </Section>

      {/* Votre site web clé en main en 7 jours */}
      <Section bgColor="bg-white" disablePaddingBottomDesktop disablePaddingBottomMobile>
        <div className="max-w-6xl mx-auto px-4 md:px-8 lg:px-16 text-center">
          {/* Titre principal */}
          <H2 className="text-camel mb-6">
            Votre site web clé en main en 7 jours ! 🚀
          </H2>

          {/* Paragraphe d’introduction */}
          <H3 className="text-text">
            Un site professionnel, rapide et optimisé
          </H3>
          <p className="text-lg mb-8">
            Vous êtes entrepreneur, indépendant ou petite entreprise et vous
            avez besoin d’un site web moderne et efficace pour attirer plus de
            clients ? Je vous propose un site clé en main en seulement 7 jours,
            adapté à vos besoins et optimisé pour Google.
          </p>

          {/* Cards des points clés */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {/* Card 1 - Site sur-mesure et responsive */}
            <div className="bg-gray-100 shadow-lg rounded-lg p-6 flex flex-col text-center items-center">
              <FaLaptopCode className="text-camel text-4xl mb-3" />
              <p className="font-bold">Site sur-mesure et responsive</p>
              <p className="text-sm mt-2">
                Un design professionnel adapté à tous les écrans pour une
                expérience utilisateur optimale.
              </p>
            </div>

            {/* Card 2 - Optimisation SEO */}
            <div className="bg-gray-100 shadow-lg rounded-lg p-6 flex flex-col text-center items-center">
              <FaChartLine className="text-camel text-4xl mb-3" />
              <p className="font-bold">Optimisation SEO</p>
              <p className="text-sm mt-2">
                Votre site pensé pour le référencement naturel afin de gagner en
                visibilité sur Google.
              </p>
            </div>

            {/* Card 3 - Formulaire + Google My Business */}
            <div className="bg-gray-100 shadow-lg rounded-lg p-6 flex flex-col text-center items-center">
              <FaBusinessTime className="text-camel text-4xl mb-3" />
              <p className="font-bold">
                Formulaire de contact & Google My Business
              </p>
              <p className="text-sm mt-2">
                Simplifiez la prise de contact et maximisez votre présence
                locale en ligne.
              </p>
            </div>

            {/* Card 4 - Accompagnement & Conseils */}
            <div className="bg-gray-100 shadow-lg rounded-lg p-6 flex flex-col text-center items-center">
              <FaHandHoldingHeart className="text-camel text-4xl mb-3" />
              <p className="font-bold">Accompagnement & Conseils</p>
              <p className="text-sm mt-2">
                Je vous guide pour prendre en main votre site et optimiser votre
                stratégie sur le long terme.
              </p>
            </div>
          </div>

          {/* Conclusion + CTA */}
          <p className="text-lg mt-8 mb-6">
            📞 Prêt à lancer votre site ? Contactez-moi dès maintenant !
          </p>
          <div>
            <CTA text="Réservez un appel gratuit" link="/contact" />
          </div>
        </div>
      </Section>

      {/* Services Section */}
      <Section
        bgColor="white"
        disablePaddingBottomMobile={true}
        disablePaddingBottomDesktop={true}
      >
        <H2 className="text-center text-camel mb-8">Mes services</H2>
        <p className="text-lg text-center mb-6 max-w-3xl mx-auto">
          <strong>Élevez votre activité</strong> grâce à des{' '}
          <strong>solutions web adaptées</strong> à vos besoins. Que vous
          cherchiez un <strong>site simple</strong> ou des{' '}
          <strong>fonctionnalités avancées</strong>, je propose des{' '}
          <strong>packs clairs</strong> et des{' '}
          <strong>services personnalisés</strong> pour répondre à vos attentes.
        </p>

        <p className="text-lg text-center mb-12 max-w-3xl mx-auto">
          <strong>Donnez vie à votre projet en ligne</strong> grâce à{' '}
          <strong>mon accompagnement personnalisé</strong>.{' '}
          <strong>Créer un site web</strong>, c’est ouvrir de{' '}
          <strong>nouvelles opportunités</strong>,{' '}
          <strong>développer votre présence en ligne</strong> et{' '}
          <strong>attirer vos premiers clients</strong>.{' '}
          <strong>Faites le premier pas</strong> avec un{' '}
          <strong>site professionnel, optimisé</strong> et prêt à{' '}
          <strong>propulser votre activité</strong>.
        </p>

        {/* packs de Services */}
        <ServicesPacksComponent />

        {/* Services à la carte */}
        <div className="mt-20">
          <H2 className="text-center text-camel mb-8">Services à la carte</H2>
          <p className="text-lg text-center mb-12 max-w-3xl mx-auto">
            Des <strong>services personnalisés et flexibles</strong> pour{' '}
            <strong>optimiser votre site web</strong> et répondre à{' '}
            <strong>vos besoins spécifiques</strong>.{' '}
            <strong>Maintenance, SEO, formation</strong> : choisissez les
            options qui <strong>maximisent votre réussite en ligne</strong>.
          </p>

          <ServicesCardComponent />
        </div>
      </Section>

      {/* Section 3: Comment ça marche */}
      <Section bgColor="bg-white">
        <H2>Comment ça marche ?</H2>
        <p className="text-lg mb-6 text-center">
          Profitez d'un <strong>accompagnement personnalisé</strong>, étape par
          étape, pour <strong>mener à bien votre projet web</strong>, du{' '}
          <strong>concept à la réalisation</strong>.
        </p>

        <motion.div
          ref={processRef}
          className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center"
          initial={{ opacity: 0 }}
          animate={
            isInViewProcess ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }
          }
          transition={{ duration: 1.2 }}
        >
          <div className="home-container text-center lg:py-12">
            <div className="flex justify-center mb-6">
              <img
                src={images.illustrationQuestion}
                alt="Illustration question"
                className="max-w-full h-auto w-64 lg:w-80"
                loading="lazy"
              />
            </div>
            <TypewriterText words={words} loop={true} />
          </div>
          <div className="max-w-4xl">
            <AccordionItem
              stepNumber="01"
              title="Rencontre"
              content="Établissons une première prise de contact pour comprendre vos objectifs et vos attentes."
            />
            <AccordionItem
              stepNumber="02"
              title="Étude du projet"
              content="J’analyse vos besoins en détail et rédige un cahier des charges."
            />
            <AccordionItem
              stepNumber="03"
              title="Conception & Réalisation"
              content="Je m'occupe du développement technique en respectant les meilleures pratiques de codage."
            />
            <AccordionItem
              stepNumber="04"
              title="Livraison"
              content="Votre projet est finalisé et prêt à être mis en ligne."
            />
            <AccordionItem
              stepNumber="05"
              title="Support & Suivi"
              content="Je reste à votre disposition pour la maintenance et l'optimisation."
            />
          </div>
        </motion.div>
        <div className="text-center pt-12">
          <CTA
            className="inline bg-cta text-white hover:bg-white hover:text-camel transition-colors duration-300"
            text="Je me lance !"
            link="/contact"
          />
        </div>
      </Section>

      {/* Section 4: FAQ */}
      <FaqSection />
      {/* Section Témoignages */}
      <Section
        bgColor="bg-white"
        disablePaddingBottomMobile={true}
        disablePaddingBottomDesktop={true}
      >
        <H2 className="text-center text-camel">Ce qu'en disent mes clients</H2>
        <TestimonialSlider />
        <div className="text-center pt-12">
          <CTA
            className="inline bg-cta text-white hover:bg-white hover:text-camel transition-colors duration-300"
            text="Je me lance !"
            link="/contact"
          />
        </div>
      </Section>
    </div>
  );
};

export default Home;
