import React from 'react';
import { motion, useInView } from 'framer-motion';
import { FaTools, FaSearch, FaGraduationCap, FaClipboardCheck } from 'react-icons/fa';

const servicesData = [
  {
    title: 'Maintenance technique',
    description: 'Mise à jour, sauvegardes, et correction des bugs.',
    price: 'À partir de 50€',
    icon: <FaTools size={40} className="text-camel mb-4" />,
  },
  {
    title: 'Optimisation SEO avancée',
    description: 'Audit SEO, recherche de mots-clés, et amélioration des performances.',
    price: 'À partir de 150€',
    icon: <FaSearch size={40} className="text-camel mb-4" />,
  },
  {
    title: 'Formation personnalisée',
    description: 'Gérez votre site ou apprenez les bases du marketing digital.',
    price: '50€/session',
    icon: <FaGraduationCap size={40} className="text-camel mb-4" />,
  },
  {
    title: 'Audit de site existant',
    description: 'Analyse de votre site avec recommandations.',
    price: 'À partir de 100€',
    icon: <FaClipboardCheck size={40} className="text-camel mb-4" />,
  },
];

const ServicesCardComponent = () => {
  const sectionRef = React.useRef(null);
  const isInView = useInView(sectionRef, { once: true });

  return (
    <div
      ref={sectionRef}
      className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 px-4 lg:px-16"
    >
      {servicesData.map((service, index) => (
        <motion.div
          key={index}
          className="bg-white shadow-lg rounded-lg border border-camel/30 p-6 flex flex-col items-center text-center hover:shadow-2xl transition-shadow duration-300"
          initial={{ opacity: 0, y: 50 }}
          animate={isInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1.2, delay: index * 0.2 }}
        >
          {service.icon}
          <h3 className="text-lg font-bold text-cacao mb-2">{service.title}</h3>
          <p className="text-sm mb-4">{service.description}</p>
          <p className="text-camel text-sm font-semibold mb-4">
            <strong>Prix :</strong> {service.price}
          </p>
        </motion.div>
      ))}
    </div>
  );
};

export default ServicesCardComponent;
