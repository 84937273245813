import React from 'react';

const Section = ({
  children,
  bgColor = 'bg-gradient-to-r from-camel via-brun to-brun',
  className = '',
  disablePaddingTop = false,
  disablePaddingBottomMobile = false,
  disablePaddingBottomDesktop = false,
}) => {
  return (
    <section
      className={`px-4 md:px-8 lg:px-16 
        ${disablePaddingTop ? 'pt-0' : 'pt-16'} 
        ${disablePaddingBottomMobile ? 'pb-0' : 'pb-16'} 
        ${disablePaddingBottomDesktop ? 'lg:pb-0' : 'lg:pb-16'} 
        ${bgColor} 
        ${className}`}
    >
      {children}
    </section>
  );
};

export default Section;
